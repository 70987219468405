import React, { useRef, useState } from "react";
import "./cards-wrapper.scss";
import { AnimalCard } from "../card/animal-card";
import { animalCount } from "../../static/statics";
import CustomScroll from "react-custom-scroll";
import "react-custom-scroll/dist/customScroll.css";

const AudioContext = window.AudioContext || window.webkitAudioContext;
new AudioContext();

export const CardsWrapper = (props) => {
  const containerRef = useRef();
  const [initAudio, setInitAudio] = useState(false);
  const [itemOpen, setItemOpen] = useState(false);
  const handleScroll = (e) => {
    const itemAmount = props.cardWidth / 3;
    const scrollTop = Math.abs(
      containerRef.current.getBoundingClientRect().top
    );
    let targetItemIndex = Math.ceil(
      ((scrollTop - (scrollTop % itemAmount)) / itemAmount).toFixed(2)
    );
    if (targetItemIndex >= animalCount) {
      targetItemIndex = animalCount - 1;
    }
    if (
      targetItemIndex !==
      parseInt(containerRef.current.getAttribute("centered"))
    ) {
      document
        .querySelector(
          `.juu-card-slide-audio-${Math.floor(Math.random() * 10) + 1}`
        )
        .play();
    }
    if (document.querySelector(".juu-animal-card.centered")) {
      document
        .querySelector(".juu-animal-card.centered")
        .classList.remove("centered");
    }
    containerRef.current.setAttribute("centered", targetItemIndex);
    document
      .querySelector(`.juu-animal-card.item-index-${targetItemIndex}`)
      .classList.add("centered");
  };
  return (
    <>
      {props.animalsList && (
        <CustomScroll
          className="crazy-scroll"
          minScrollHandleHeight={100}
          freezePosition={itemOpen}
          heightRelativeToParent="100%"
          onScroll={handleScroll}
        >
          <div className="juu-cards-wrapper" centered="0" ref={containerRef}>
            <audio
              className="juu-card-focus-audio"
              src="assets/audio/card-focus.mp3"
            />
            <audio
              className="juu-card-blur-audio"
              src="assets/audio/card-blur.mp3"
            />
            <div className="juu-cards-filter" />
            {Array(10)
              .fill(null)
              .map((v, i) => (
                <audio
                  className={`juu-card-slide-audio-${i + 1}`}
                  src={`assets/audio/card-slide-${i + 1}.mp3`}
                  key={`slide-audio-${i + 1}`}
                />
              ))}
            {props.animalsList
              .concat(Array(animalCount - props.animalsList.length).fill(null))
              .map((animal, i) => (
                <AnimalCard
                  itemIndex={i}
                  onSelected={(selected) => {
                    if (selected) {
                      containerRef.current.classList.add("an-item-is-selected");
                      setItemOpen(true);
                    } else {
                      containerRef.current.classList.remove(
                        "an-item-is-selected"
                      );
                      setItemOpen(false);
                    }
                    if (!initAudio) {
                      document
                        .querySelectorAll(
                          "audio:not([class^=juu-animal-card-audio-])"
                        )
                        .forEach((au) => au.play());
                      setInitAudio(true);
                    }
                  }}
                  key={i}
                  name={animal ? animal.name : "Animal_" + i}
                  description={
                    animal
                      ? animal.description
                      : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed posuere nisi."
                  }
                  image={animal ? animal.image : ""}
                  audio={animal ? animal.audio : false}
                  fieldType={animal ? animal.fieldType : "default"}
                />
              ))}
          </div>
        </CustomScroll>
      )}
    </>
  );
};
