import React, { useEffect, useState } from "react";
import "./wrapper.scss";
import { CardsWrapper } from "../cards-wrapper/cards-wrapper";

export const Wrapper = (props) => {
  const calculatedWidth = parseInt(
    Math.min((window.innerWidth / 100) * 75, (window.innerHeight / 100) * 35)
  );

  const [dataSet, setDataSet] = useState();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.get("lang") || "tr";
    fetch(`lang/${lang}.json`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((langData) => {
        console.log(langData);
        setDataSet(langData);
      });
  }, []);
  return (
    <>
      <style type="text/css" rel="stylesheet">
        {`
              html:root{
                --juu-card-width: ${calculatedWidth}px;
                --juu-card-height: ${parseInt((calculatedWidth / 3) * 4)}px;
              }`}
      </style>
      <div className="juu-wrapper">
        <CardsWrapper animalsList={dataSet} cardWidth={calculatedWidth} />
      </div>
    </>
  );
};
