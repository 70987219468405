import React, { useRef } from "react";
import { cancelEvent } from "../../static/event";
import "./animal-card.scss";

/**
 * @typedef {Object} AnimalCardProps
 * @property {string} [name]
 * @property {string} [description]
 * @property {string} [image]
 * @property {string} [audio]
 * @property {string} [color]
 * @property {string} [colorAlt]
 * @property {number} [itemIndex]
 * @property {(e:boolean):void=>{}} [onSelected]
 * @property {(e:any):void=>{}} [onFlip]
 */

/**
 * AnimalCard
 * @type {import('react').FunctionComponent<AnimalCardProps>}
 */
export const AnimalCard = (props) => {
  const wrapper = useRef();
  let controlStarted = 0;
  const handleCardOpen = (e) => {
    cancelEvent(e);
    if (controlStarted !== -1) {
      const item = wrapper.current;
      wrapper.current
        .querySelector(".juu-animal-card-inner")
        .classList.remove("juu-animal-card-no-anim");
      if (item.classList.contains("open")) {
        document.querySelector(".juu-card-blur-audio").play();
        item.classList.remove("shadow");
        props.onSelected(false);
        item.classList.remove("open");
        item.setAttribute(
          "style",
          `transform:translate3d(0, 75px, 0) rotateZ(var(--item-rotation-${props.itemIndex})); z-index:1000;`
        );
        setTimeout(() => {
          item.setAttribute(
            "style",
            `transform:translate3d(0, 75px, 0) rotateZ(var(--item-rotation-${props.itemIndex}));`
          );
          setTimeout(() => {
            item.setAttribute(
              "style",
              `transform:translate3d(0, 0, 0) rotateZ(var(--item-rotation-${props.itemIndex}));z-index: var(--item-z-${props.itemIndex});`
            );
            setTimeout(() => {
              item.removeAttribute("style");
            }, 350);
          }, 10);
        }, 250);
      } else {
        document.querySelector(".juu-card-focus-audio").play();
        item.setAttribute(
          "style",
          `transform:translate3d(0, 75px, 0) rotateZ(var(--item-rotation-${props.itemIndex}));`
        );
        setTimeout(() => {
          item.setAttribute(
            "style",
            `transform:translate3d(0, 75px, 0) rotateZ(var(--item-rotation-${props.itemIndex})); z-index: 1000;`
          );
          item.classList.add("open");
          item.classList.add("shadow");
          props.onSelected(true);
          setTimeout(() => {
            item.setAttribute(
              "style",
              "transform:translate3d(0, 0, 0) scale(1.2); z-index:1000;"
            );
          }, 10);
        }, 200);
      }
    } else {
      controlStarted = 0;
    }
  };

  let touchInitX = 0;
  let touchDiff = 0;
  const handleCardFlip = (e) => {
    const item = wrapper.current.querySelector(".juu-animal-card-inner");
    touchDiff = (Math.floor(e.touches[0].pageX - touchInitX) / 3) * 2;
    if (Math.abs(touchDiff) > 30) {
      item.classList.add("juu-animal-card-no-anim");
      const applicableDiff = touchDiff < 0 ? touchDiff + 30 : touchDiff - 30;
      const lastYRotation = parseInt(item.getAttribute("y-rotation"));
      item.setAttribute(
        "style",
        `transform: translate3d(0, 0, 0) rotateY(${lastYRotation +
          applicableDiff}deg) rotateX(${applicableDiff / 20}deg);`
      );
    }
  };
  const handleRotationStart = (e) => {
    touchDiff = 0;
    touchInitX = e.touches[0].pageX;
  };
  const handleRotationEnd = (e) => {
    if (Math.abs(touchDiff) > 40) {
      const item = wrapper.current.querySelector(".juu-animal-card-inner");
      item.classList.remove("juu-animal-card-no-anim");
      const lastYRotation = parseInt(item.getAttribute("y-rotation"));
      const nextRotation =
        (lastYRotation - (lastYRotation % 180)) / 180 +
        (touchDiff > 40 ? 1 : touchDiff < -40 ? -1 : 0);
      item.setAttribute(
        "style",
        `transform: translate3d(0, 0, 0) rotateY(${nextRotation * 180}deg);`
      );
      item.setAttribute("y-rotation", nextRotation * 180);

      if (lastYRotation !== nextRotation * 180) {
        document
          .querySelector(
            `.juu-card-slide-audio-${Math.floor(Math.random() * 10) + 1}`
          )
          .play();
      }
    }
  };

  /** MOUSE */
  const handleCardFlipMouse = (e) => {
    if (controlStarted) {
      const item = wrapper.current.querySelector(".juu-animal-card-inner");
      touchDiff = (Math.floor(e.pageX - touchInitX) / 3) * 2;
      if (Math.abs(touchDiff) > 30) {
        controlStarted = -1;
        item.classList.add("juu-animal-card-no-anim");
        const applicableDiff = touchDiff < 0 ? touchDiff + 30 : touchDiff - 30;
        const lastYRotation = parseInt(item.getAttribute("y-rotation"));
        item.setAttribute(
          "style",
          `transform: translate3d(0, 0, 0) rotateY(${lastYRotation +
            applicableDiff}deg) rotateX(${applicableDiff / 20}deg);`
        );
      }
    }
  };
  const handleRotationStartMouse = (e) => {
    controlStarted = 1;
    touchDiff = 0;
    touchInitX = e.pageX;
    window.addEventListener("mousemove", handleCardFlipMouse, false);
    window.addEventListener("mouseup", handleRotationEndMouse, false);
  };
  const handleRotationEndMouse = () => {
    if (Math.abs(touchDiff) > 40) {
      const item = wrapper.current.querySelector(".juu-animal-card-inner");
      item.classList.remove("juu-animal-card-no-anim");
      const lastYRotation = parseInt(item.getAttribute("y-rotation"));
      const nextRotation =
        (lastYRotation - (lastYRotation % 180)) / 180 +
        (touchDiff > 40 ? 1 : touchDiff < -40 ? -1 : 0);
      item.setAttribute(
        "style",
        `transform: translate3d(0, 0, 0) rotateY(${nextRotation * 180}deg);`
      );
      item.setAttribute("y-rotation", nextRotation * 180);

      if (lastYRotation !== nextRotation * 180) {
        document
          .querySelector(
            `.juu-card-slide-audio-${Math.floor(Math.random() * 10) + 1}`
          )
          .play();
      }
    }
    window.removeEventListener("mousemove", handleCardFlipMouse);
    window.removeEventListener("mouseup", handleRotationEndMouse);
  };

  const playAnimalAudio = (e) => {
    cancelEvent(e);
    document.querySelector(`.juu-animal-card-audio-${props.itemIndex}`).play();
  };

  const stopAnimalAudio = (e) => {
    cancelEvent(e);
    document.querySelector(`.juu-animal-card-audio-${props.itemIndex}`).pause();
    document.querySelector(
      `.juu-animal-card-audio-${props.itemIndex}`
    ).currentTime = 0;
  };

  return (
    <div
      ref={wrapper}
      onClick={handleCardOpen}
      className={`juu-animal-card juu-field-type-${
        props.fieldType
      } item-index-${props.itemIndex} ${!props.itemIndex ? "centered" : ""}`}
    >
      <div
        className="juu-animal-card-inner"
        y-rotation="0"
        onTouchStart={handleRotationStart}
        onTouchMove={handleCardFlip}
        onTouchCancel={handleRotationEnd}
        onTouchEnd={handleRotationEnd}
        onMouseDown={handleRotationStartMouse}
      >
        <div
          className="juu-animal-card-container"
          style={{
            backgroundImage: `url(${props.image})`,
          }}
        >
          <div className="juu-animal-card-name">{props.name}</div>
        </div>
        <div className="juu-animal-card-backface">
          <div
            className="juu-animal-card-backface-background"
            style={{
              backgroundImage: `url(${props.image})`,
            }}
          />
          <div className="juu-animal-card-name">{props.name}</div>
          <div className="juu-animal-card-description">
            {props.description}
            {props.children}
          </div>

          {props.audio && (
            <>
              <div
                className="juu-animal-card-audio-button"
                onClick={cancelEvent}
                onMouseDown={playAnimalAudio}
                onMouseUp={stopAnimalAudio}
                onMouseOut={stopAnimalAudio}
                onMouseLeave={stopAnimalAudio}

                onTouchStart={playAnimalAudio}
                onTouchEnd={stopAnimalAudio}
                onTouchCancel={stopAnimalAudio}
                onTouchMove={cancelEvent}
              />
              <audio
                className={`juu-animal-card-audio-${props.itemIndex}`}
                src={props.audio}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
