export const cancelEvent = (e) => {
  if (e && e.stopPropagation) {
    // e.preventDefault();
    e.stopPropagation();
    if (e.nativeEvent && e.nativeEvent.preventDefault) {
      // e.nativeEvent.preventDefault();
      e.nativeEvent.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }
  }
  return false;
};
